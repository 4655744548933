import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReactComponent as Person } from 'assets/svg/detailspage/person.svg'
import AccordionItem from 'components/common/Accordion/components/AccordionItem'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { getStatusName } from 'helper/translations'
import { OFFER_STATES } from 'components/offer/constants'

import { SectionProps } from '../scheme'

export const WasteProducerSection: FC<
  SectionProps & {
    hideStatusField?: boolean
    offer: Offer
    userIsMaklerPremium?: boolean
    userIsEmpto?: boolean
  }
> = ({
  addScrollElement,
  offer,
  hideStatusField = false,
  userIsMaklerPremium,
  userIsEmpto,
}) => {
  const iconTitle = (
    <>
      <Person className='inquiry-details-page__section-header' />
      {I18n.t('inquiryDetailsTranslations.section.wasteProducer.title')}
    </>
  )

  return (
    <AccordionItem addScrollElement={addScrollElement} title={iconTitle}>
      <div
        className='inquiry-details-page__section uk-child-width-1-2@m'
        data-uk-grid=''
      >
        {/* COMPANY NAME */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.wasteProducer.fields.name',
          )}
          linkTo={`/company/${offer.customer_company}`}
          value={offer.customer_company_object.name}
        />
        {/* COMPANY CREATED_AT */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.wasteProducer.fields.createdAt',
          )}
          value={moment(offer.customer_company_object.created_at).format('L')}
        />
        {/* COMPANY POSITIVE REVIEWS COUNT */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.wasteProducer.fields.positiveReviews',
          )}
          value={
            offer.customer_company_object.evaluation_rate === null
              ? I18n.t('companyInfoPage.noRatingYet')
              : I18n.t('companyInfoPage.rating', {
                  positivePercentage: (
                    (offer.customer_company_object.evaluation_rate ?? 0) * 100
                  ).toFixed(0),
                  numberEvaluations:
                    offer.customer_company_object.number_of_evaluations,
                })
          }
        />
        {/* INQUIRY NUMBER AND STATUS */}
        {!hideStatusField && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.wasteProducer.fields.numberAndStatus',
            )}
            value={`${offer.id}, ${getStatusName(
              offer.status,
              OFFER_STATES,
              offer.canceled_by_empto,
            )}`}
          />
        )}
        {/* INQUIRY CREATED BY */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.wasteProducer.fields.createdBy',
          )}
          value={
            offer.created_by &&
            `${offer.created_by.first_name} ${offer.created_by.last_name}`
          }
        />
        {/* RECEIVER EMAIL ADDRESS */}
        {(userIsMaklerPremium || userIsEmpto) && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.wasteProducer.fields.receiverEmailAddress',
            )}
            value={
              offer &&
              (offer.receiver_email_address != null
                ? `${offer.receiver_email_address}`
                : '-')
            }
          />
        )}
      </div>
    </AccordionItem>
  )
}
