import '../style.scss'

import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { resetMaklerPremiumCompanies } from 'actions/company'
import { getOrders } from 'actions/order'
import { DropDownInput } from 'components/common/DropDownInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { createRequestStatusSelector } from 'selectors/requestStatus'
import { APP_CONSTANTS } from 'constants/app'
import { Select } from 'components/Select/Select'

import {
  InvoiceCheckPositionsFormContext,
  InvoiceCheckPositionsFormValues,
} from '../InvoiceCheckPositionsFormSteps'

export const CollectionAddressForm: FC = () => {
  const dispatch = useDispatch()

  const {
    errors,
    handleChange,
    setFieldTouched,
    setFieldValue,
    setValues,
    submitCount,
    resetForm,
    values,
  } = useFormikContext<InvoiceCheckPositionsFormValues>()

  const { stepStatus, saveStep, invoiceCheckPosition } = useContext(
    InvoiceCheckPositionsFormContext,
  )

  const isFieldValid = name => values[name]?.length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  const searchFields = [
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.positionsForm.steps.1.fields.company_name.label',
      ),
      optionValue: 'company_name',
    },
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.positionsForm.steps.1.fields.order.label',
      ),
      optionValue: 'order',
    },
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.positionsForm.steps.1.fields.zipcode.label',
      ),
      optionValue: 'zipcode',
    },
  ]

  const orderRequestStatus = useSelector(
    createRequestStatusSelector(['GET_ORDER']),
  )
  useEffect(() => {
    if (values.offer) {
      if (!orderRequestStatus) {
        dispatch(getOrders(undefined, { offer: values.offer }, 1))
      }
    }
  }, [values.offer, orderRequestStatus, dispatch])

  return (
    <div className='invoicecheck-form__step'>
      <DropDownInput
        choices={searchFields}
        label={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.1.fields.search_field.label',
        )}
        name='search_field'
        onChange={e => {
          dispatch(resetMaklerPremiumCompanies()) // Reset Filtered results
          setValues({
            collection_address: undefined,
            company_name: undefined,
            order: undefined,
            zipcode: undefined,
          })
          handleChange(e)
        }}
        showCheckmark={!!values.search_field}
        value={values.search_field}
        withCheckmark
        isDisabled={!!invoiceCheckPosition?.id}
      />
      {/* prettier-ignore */}
      <Select
        urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/with-orders/?${values.search_field}=`}
        urlPart2={`&role=1`}
        getValue={(e) => e.id}
        getLabel={(e) => `${e?.company_name} / ${e?.display_name} / ${e?.customer_no}`}
        label={I18n.t(`invoiceCheckTranslations.positionsForm.steps.1.fields.${values.search_field}.label`)}
        error={getFieldError}
        setValue={e => {
          if (e?.value !== values.collection_address) {
            const order = values.order
            resetForm()
            setFieldValue('order', order)
          }
          setFieldValue('collection_address', e?.value)
          setFieldValue('collection_address_label', e?.label)

        }}
        setRawValue={e => {
          const { search_field = 'company_name' } = values
          setFieldValue('search_field', search_field)
          setFieldTouched(search_field, true)
          setFieldValue(search_field, e)
          if (search_field === 'order') {
            setValues({...values, order: e})
          }
        }
        }
        selectedValue={values[`collection_address`] ? values[`collection_address`] : null}
        selecting={'address'}
        cacheOptions={false}
        withCheckmark
      />
      <GoToNextFormStepButton
        buttonText={
          stepStatus === STEP_STATUS.EDITING
            ? I18n.t('general.button.save')
            : I18n.t('createInquiryTranslations.form.button.nextStep')
        }
        onClick={saveStep}
        isDisabled={!values.collection_address}
      />
    </div>
  )
}
