import './style.scss'

import classNames from 'classnames'
import { Formik } from 'formik'
import moment from 'moment'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import { StaticContext } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import Spinner from 'react-spinkit'
import * as yup from 'yup'

import { resetApiFetchLoading } from 'actions/app'
import { getContainers } from 'actions/container'
import { resetPriceComparisonTable } from 'actions/maklerpremium'
import { getInvoiceCheckPositions } from 'actions/invoicecheck'
import { getOffer } from 'actions/offer'
import { createOrder, getOrders } from 'actions/order'
import { ReactComponent as Document } from 'assets/svg/detailspage/document.svg'
import { ReactComponent as Truck } from 'assets/svg/detailspage/truck.svg'
import { COLLECTION_CONTAINER_IDS } from 'constants/app'
import { BREAKPOINT } from 'constants/design'
import { UserPermission } from 'constants/user'
import {
  decimalToGermanFormat,
  germanDecimalToInternationalFormat,
} from 'helper/general'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector } from 'selectors/indexes'
import { createLoadingSelector } from 'selectors/loading'
import { getInvoiceCheckPositionPaginationSelector } from 'selectors/invoicecheck'
import { getOfferSelector } from 'selectors/offer'
import { getOrdersSelector } from 'selectors/order'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'
import { getCurrentUserSelector } from 'selectors/user'

import AccordionItem from '../../common/Accordion/components/AccordionItem'
import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import { DetailsPage } from '../../common/DetailsPage'
import { CallToActionHeader } from '../../common/DetailsPage/components/CallToActionSection/CallToAction/CallToActionHeader'
import { PageType } from '../../common/DetailsPage/constants'
import { AddressSection } from '../../common/DetailsPage/sections/AddressSection'
import { InquirySection } from '../../common/DetailsPage/sections/InquirySection'
import { PreviewAttachmentModal } from '../../common/DetailsPage/sections/InquirySection/PreviewAttachmentModal'
import { PriceSection } from '../../common/DetailsPage/sections/PriceSection'
import { TimeSection } from '../../common/DetailsPage/sections/TimeSection'
import { WasteDisposerSection } from '../../common/DetailsPage/sections/WasteDisposerSection'
import { WasteProducerSection } from '../../common/DetailsPage/sections/WasteProducerSection'
import { DismissableInfo } from '../../common/DismissableInfo'
import { ErrorMessages } from '../../common/ErrorMessages'
import FilePreviewComponent from '../../common/FilePreview'
import Icon, { ICON_NAME } from '../../common/Fontello'
import { LinkButton } from '../../common/LinkButton'
import Paragraph, { PARAGRAPH_ALIGN } from '../../common/Paragraph'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { NotFoundComponent } from '../../common/RoutesHandler/components/NotFoundComponent'
import {
  OFFER_ORDER_TYPE,
  INQUIRY_STANDING_ORDER_TYPES,
  OFFER_TIME_OF_DAY,
} from '../../inquiry/constants'
import {
  getBusinessSegments,
  getGroups,
  getRoles,
} from '../../maklerpremium/helpers'
import { OFFER_STATUS } from '../../offer/constants'
import { CancelOfferModal } from '../../offer/OfferDetailsPage/CancelOfferModal'

import { ChangeAgreementPeriodModal } from './ChangeAgreementPeriodModal'
import { ChangeDisposerModal } from './ChangeDisposerModal'
import { SHOULD_FETCH_INVOICE_CHECK_POSITION } from './constants'
import { ConvertInquiryModal } from './ConvertInquiryModal'
import { CreateNewOrderButton } from './CreateNewOrderButton'
import { getCurrentContainer, getPageTitleForAgreement } from './helper'
import { getDefaultDate, NewOrderFields } from './NewOrderFields'
import { PostponeIntervalModal } from './PostponeIntervalModal'
import { ActivitiesSection } from './sections/ActivitiesSection'
import { AgreementSection } from './sections/AgreementSection'
import { NewOrderSection } from './sections/NewOrderSection'

const AgreementDetailsPageComponent: FC<
  RouteComponentProps<any, StaticContext, any>
> = ({
  location,
  match: {
    params: { agreementId },
  },
}) => {
  const dispatch = useDispatch()

  // Convert Inquiry Modal
  const [showConvertInquiryModal, setShowConvertInquiryModal] = useState(false)
  // Cancel Offer Modal
  const [showCancelOfferModal, setShowCancelOfferModal] = useState(false)
  // Postpone interval Modal
  const [showPostponeIntervalModal, setShowPostponeIntervalModal] =
    useState(false)
  // Change disposer Modal
  const [showChangeDisposerModal, setShowChangeDisposerModal] = useState(false)
  // Hide creation form
  const [hideCreateForm, setHideCreateForm] = useState(true)
  // Change agreement period
  const [
    showChangeAgreementIntervalModal,
    setShowChangeAgreementIntervalModal,
  ] = useState(false)

  const [isConvertible, setIsConvertible] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [needsToFetchOrders, setNeedsToFetchOrders] = useState(true)
  const [needsToRefetchInvoiceChecks, setNeedsToRefetchInvoiceChecks] =
    useState<SHOULD_FETCH_INVOICE_CHECK_POSITION>(
      SHOULD_FETCH_INVOICE_CHECK_POSITION.DO_NOT_FETCH,
    )

  const handlePreviewClick = file => {
    setOpenModal(!openModal)
    setSelectedFile(file)
  }

  const isLoading = {
    offer: useSelector(createLoadingSelector(['GET_OFFER'])),
    containers: useSelector(createLoadingSelector(['GET_CONTAINERS'])),
    fractions: useSelector(createLoadingSelector(['GET_FRACTIONS'])),
    indexes: useSelector(createLoadingSelector(['GET_INDEXES'])),
    orders: useSelector(createLoadingSelector(['GET_ORDERS'])),
    createOrder: useSelector(createLoadingSelector(['CREATE_ORDER'])),
    invoiceCheckPositions: useSelector(
      createLoadingSelector(['GET_INVOICE_CHECK_POSITIONS']),
    ),
  }

  const requestStatus = {
    orders: useSelector(createRequestStatusSelector(['GET_ORDERS'])),
    invoiceCheckPositions: useSelector(
      createRequestStatusSelector(['GET_INVOICE_CHECKS']),
    ),
  }

  const offer = useSelector(getOfferSelector)
  const containerList = useSelector(getContainersSelector)
  const fractionList = useSelector(getFractionsSelector)
  const indexList = useSelector(getIndexesSelector)

  const user = useSelector(getCurrentUserSelector)
  const orders = useSelector(getOrdersSelector)
  const invoiceCheckPositions = useSelector(
    getInvoiceCheckPositionPaginationSelector,
  )

  const { isMaklerPremium } = getRoles(user)
  const { isAdministrator } = getGroups(user)
  const { isEPD, isEmpto } = getBusinessSegments(user)

  const showConvertInquiryButton = useMemo(
    () =>
      moment().isBefore(moment(offer.collection_date)) &&
      offer.order_type === OFFER_ORDER_TYPE.TYPE_ONE_TIME &&
      isMaklerPremium,
    [offer.collection_date, offer.order_type, isMaklerPremium],
  )
  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(offer.container),
  )
  const showChangeDisposerButton = useMemo(() => {
    return (
      moment().subtract(1, 'days').diff(offer.service_start_date, 'month') <
        2 &&
      invoiceCheckPositions.total_results < 1 &&
      (Number(offer!.order_type) === OFFER_ORDER_TYPE.TYPE_ONE_TIME ||
        Number(offer!.order_type) === OFFER_ORDER_TYPE.TYPE_QUICK)
    )
  }, [invoiceCheckPositions.total_results, offer])

  useEffect(() => {
    if (requestStatus.orders === REQUEST_STATUS.SUCCESS) {
      setNeedsToFetchOrders(
        !!(orders.length && orders[0].offer_id !== Number(agreementId)),
      )
    }
  }, [agreementId, requestStatus.orders, orders])

  useEffect(() => {
    if (
      requestStatus.orders === REQUEST_STATUS.SUCCESS &&
      needsToRefetchInvoiceChecks ===
        SHOULD_FETCH_INVOICE_CHECK_POSITION.WAIT_FOR_NEW_ORDER_ID
    ) {
      setNeedsToRefetchInvoiceChecks(
        SHOULD_FETCH_INVOICE_CHECK_POSITION.FETCH_NEW,
      )
    }
    if (requestStatus.orders === REQUEST_STATUS.LOADING) {
      setNeedsToRefetchInvoiceChecks(
        SHOULD_FETCH_INVOICE_CHECK_POSITION.WAIT_FOR_NEW_ORDER_ID,
      )
    }
  }, [needsToRefetchInvoiceChecks, requestStatus.orders])

  // DISPATCH OFFER
  useEffect(() => {
    dispatch(
      getOffer(Number(agreementId), [
        'company_object__evaluation_rate',
        'customer_company_object__evaluation_rate',
        'company_object__offers_count',
        'disposer_prices',
      ]),
    )
  }, [agreementId, dispatch])

  // DISPATCH CONTAINER
  useEffect(() => {
    if (containerList.length < 1) {
      dispatch(getContainers())
    }
  }, [containerList, dispatch])

  // DISPATCH ORDER
  useEffect(() => {
    if (needsToFetchOrders && !isLoading.orders) {
      dispatch(getOrders(1, { offer: agreementId }))
      setNeedsToFetchOrders(false)
    }
  }, [dispatch, isLoading.orders, needsToFetchOrders, agreementId])

  // DISPATCH INVOICE_CHECK_POSITION
  useEffect(() => {
    if (
      !isLoading.invoiceCheckPositions &&
      orders.length > 0 &&
      needsToRefetchInvoiceChecks ===
        SHOULD_FETCH_INVOICE_CHECK_POSITION.FETCH_NEW
    ) {
      dispatch(getInvoiceCheckPositions(1, { order_id: orders[0].id }))
      setNeedsToRefetchInvoiceChecks(0)
    }
  }, [
    dispatch,
    invoiceCheckPositions.loaded,
    isLoading.invoiceCheckPositions,
    needsToRefetchInvoiceChecks,
    orders,
  ])

  // IS CONVERTIBLE
  useEffect(() => {
    if (
      offer?.id &&
      String(offer.order_type) === String(OFFER_ORDER_TYPE.TYPE_ONE_TIME) &&
      orders.length > 0
    ) {
      setIsConvertible(orders[0].is_convertible)
    }
  }, [offer.id, offer.order_type, orders])

  if (
    isLoading.offer ||
    isLoading.containers ||
    isLoading.fractions ||
    isLoading.indexes ||
    isLoading.invoiceCheckPositions ||
    !agreementId ||
    !user ||
    !offer.id ||
    !offer
  )
    return (
      <div className='uk-flex uk-flex-center uk-margin-large-top'>
        <Spinner name='circle' />
      </div>
    )

  // Only show agreement page for actual agreements, not for open/cancelled/discarded offers
  if (Number(offer.status) !== OFFER_STATUS.STATUS_ACCEPTED)
    return <NotFoundComponent />

  const sections = new Map([
    [
      'agreement',
      {
        offset: -230,
        title: (
          <Translate value='agreementDetailsTranslations.section.agreement.title' />
        ),
        component: (
          <AgreementSection
            addScrollElement='agreement'
            offer={offer}
            key='agreementSection'
          />
        ),
      },
    ],
    [
      'wasteProducer',
      {
        offset: -230,
        title: (
          <Translate value='inquiryDetailsTranslations.section.wasteProducer.title' />
        ),
        component: (
          <WasteProducerSection
            addScrollElement='wasteProducer'
            offer={offer}
            key='wasteProducerSection'
            hideStatusField
          />
        ),
      },
    ],
    [
      'wasteDisposer',
      {
        offset: -230,
        title: (
          <Translate value='agreementDetailsTranslations.section.wasteDisposer.title' />
        ),
        component: (
          <WasteDisposerSection
            addScrollElement='wasteDisposer'
            offer={offer}
            key='wasteDisposerSection'
          />
        ),
      },
    ],
    [
      'address',
      {
        offset: -230,
        title: (
          <Translate value='inquiryDetailsTranslations.section.address.title' />
        ),
        component: (
          <AddressSection
            addScrollElement='address'
            offer={offer}
            key='addressSection'
          />
        ),
      },
    ],
    [
      'time',
      {
        offset: -230,
        title: (
          <Translate value='inquiryDetailsTranslations.section.time.title' />
        ),
        component: (
          <TimeSection
            addScrollElement='time'
            containerList={containerList}
            offer={offer}
            key='timeSection'
          />
        ),
      },
    ],
    [
      'inquiry',
      {
        offset: -230,
        title: (
          <Translate value='inquiryDetailsTranslations.section.inquiry.title' />
        ),
        component: (
          <InquirySection
            addScrollElement='inquiry'
            containerList={containerList}
            fractionList={fractionList}
            offer={offer}
            key='inquirySection'
          />
        ),
      },
    ],
    [
      'price',
      {
        offset: -240,
        title: (
          <Translate value='agreementDetailsTranslations.section.price.title' />
        ),
        component: (
          <PriceSection
            addScrollElement='price'
            indexList={indexList}
            offer={offer}
            key='priceSection'
          />
        ),
      },
    ],
    // Adding empty attachments to maintain the correct order of sections
    [
      'attachments',
      {
        offset: -240,
        title: null,
        component: null,
      },
    ],
    [
      'activities',
      {
        offset: -240,
        title: (
          <Translate value='agreementDetailsTranslations.section.activities.title' />
        ),
        component: (
          <ActivitiesSection
            addScrollElement='activities'
            offer={offer}
            key='activitiesSection'
          />
        ),
      },
    ],
  ])
  if (offer.attachments && offer.attachments.length > 0) {
    sections.set('attachments', {
      offset: -240,
      title: (
        <Translate value='inquiryDetailsTranslations.section.inquiry.fields.attachments' />
      ),
      component: (
        <AccordionItem
          addScrollElement={'attachments'}
          title={
            <>
              <Document className='inquiry-details-page__section-header' />
              {I18n.t(
                'inquiryDetailsTranslations.section.inquiry.fields.attachments',
              )}
            </>
          }
        >
          {offer.attachments && offer.attachments.length > 0 && (
            <div className='uk-width-1-1'>
              {/* UPLOADED FILES PREVIEW */}
              <div
                className='inquiry-details-page__attachments-container uk-child-width-1-3@l'
                data-uk-grid=''
              >
                {offer.attachments.map(file => (
                  <FilePreviewComponent
                    alt={file.thumbnail}
                    key={file.thumbnail}
                    modalTitle={file.name}
                    src={file.file}
                    file={file}
                    showMultiple
                    onGetComment={() => file.text || ''}
                  />
                ))}
              </div>
            </div>
          )}
          <PreviewAttachmentModal
            callback={handlePreviewClick}
            file={selectedFile}
            modalVisible={openModal}
          />
        </AccordionItem>
      ),
    })
  }

  const breadcrumb = {
    breadcrumbTitle: (
      <Translate
        value='agreementDetailsTranslations.breadcrumbTitle'
        // always show the company of your partner inside the breadcrumb
        company={
          user!.company === offer!.company_object.id
            ? offer!.customer_company_object.name
            : offer!.company_object.name
        }
      />
    ),
    prevLinkTitle:
      (location.state && location.state.backLinkTitle) ||
      I18n.t('menu.agreement'),
    prevLinkTo: (location.state && location.state.backLinkTo) || '/agreement',
    teaserText: <Translate value='agreementDetailsTranslations.teaser.text' />,
    teaserTitle: (
      <Translate
        value='agreementDetailsTranslations.teaser.title'
        agreementId={offer!.id}
        address={offer!.collection_address_object.display_name}
      />
    ),
  }

  const callToAction = {
    header: <></>,
    buttonsOrFields: [
      <CreateNewOrderButton
        fullWidth
        offer={offer}
        onClick={() => setHideCreateForm(false)}
        user={user}
        key='createNewOrderButton'
        dataTestId='inquiry-details-create-new-order-button'
      />,
      <LinkButton
        className='show-previous-orders-button'
        fullWidth
        showTooltip
        tooltipText={I18n.t(
          'offerDetails.agreementOffer.tooltip.noPreviousOrders',
        )}
        isDisabled={offer.number_of_associated_orders === 0}
        target={{
          pathname: `/agreement/${offer!.id}/orders/`,
          state: {
            from: Object.assign(location, {
              breadcrumb:
                user && offer && getPageTitleForAgreement(offer, user),
              from: {
                pathname: '/agreement',
                breadcrumb: I18n.t('acceptedOffers.heading'),
              },
            }),
          },
        }}
        key=' previousOrdersButton'
      >
        <Translate value='offerDetails.agreementOffer.previousOrders' />
        <Icon name={ICON_NAME.LIST} />
      </LinkButton>,
      <>
        {/*Only show change agreement period button when the agreement is of a recurring type, service start is in the future, or it is active*/}
        {INQUIRY_STANDING_ORDER_TYPES.includes(
          offer.order_type as OFFER_ORDER_TYPE,
        ) &&
          (moment(offer.service_start_date).isSameOrAfter(moment(), 'days') ||
            moment(offer.service_end_date).isSameOrAfter(moment(), 'days')) &&
          (isEPD ||
            (offer.order_type === OFFER_ORDER_TYPE.TYPE_ON_DEMAND &&
              isAdministrator &&
              isEmpto)) && (
            <RequiredPermissions
              requiredPermissions={[
                UserPermission.CHANGE_RECURRING_OFFER_TIME_PERIOD,
                UserPermission.POSTPONE_INTERVAL_OFFER,
              ]}
              key='changeAgreementDetailsButton'
            >
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                onClick={() => setShowChangeAgreementIntervalModal(true)}
                key='agreement-details-change-agreement-period-button'
                fullWidth
                dataTestId='agreement-details-change-agreement-period'
              >
                <Translate value='agreementDetailsTranslations.changeAgreementPeriodButton.buttonText' />
              </Button>
            </RequiredPermissions>
          )}
      </>,
    ],
    hideMoreInfo: true,
  }

  if (
    offer.order_type === OFFER_ORDER_TYPE.TYPE_ONE_TIME &&
    showConvertInquiryButton &&
    isMaklerPremium
  ) {
    callToAction.buttonsOrFields.push(
      <RequiredPermissions
        requiredPermissions={[UserPermission.SEND_CONVERT_INQUIRY]}
        key='convertInquiryButton'
      >
        <Button
          fullWidth
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          onClick={() => setShowConvertInquiryModal(true)}
          isDisabled={!isConvertible}
          key='convertInquiryButton'
          dataTestId='inquiry-details-convert-inquiry'
        >
          <Translate value='agreementDetailsTranslations.convertInquiryButton.buttonText' />
        </Button>
      </RequiredPermissions>,
    )
  }

  if (offer.order_type === OFFER_ORDER_TYPE.TYPE_RECURRING && isMaklerPremium) {
    callToAction.buttonsOrFields.push(
      <RequiredPermissions
        requiredPermissions={[UserPermission.POSTPONE_INTERVAL_OFFER]}
        key='postponeIntervalButton'
      >
        <>
          <hr />
          <CallToActionHeader
            title={I18n.t(
              'agreementDetailsTranslations.cta.postponeInterval.title',
            )}
            key='offer-details-postpone-interval-header'
          />
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            onClick={() => setShowPostponeIntervalModal(true)}
            key='agreement-details-postpone-interval-button'
            fullWidth
            dataTestId='agreement-details-postpone-interval-button'
          >
            <Translate value='agreementDetailsTranslations.postponeIntervalButton.buttonText' />
          </Button>
        </>
      </RequiredPermissions>,
    )
  }

  if (isMaklerPremium && showChangeDisposerButton) {
    callToAction.buttonsOrFields.push(
      <RequiredPermissions
        requiredPermissions={[UserPermission.CHANGE_DISPOSER_OFFER]}
        key='changeDisposerButton'
      >
        <Button
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          onClick={() => setShowChangeDisposerModal(true)}
          key='agreement-details-change-disposer-button'
          fullWidth
          dataTestId='agreement-details-change-disposer-button'
        >
          <Translate value='agreementDetailsTranslations.changeDisposerButton.buttonText' />
        </Button>
      </RequiredPermissions>,
    )
  }

  if (
    isMaklerPremium &&
    offer.mp_can_cancel &&
    offer.customer_company_object.makler_premium_company
  ) {
    callToAction.buttonsOrFields.push(
      <Button
        backgroundColor={BUTTON_BACKGROUND_COLOR.DANGER}
        onClick={() => {
          setShowCancelOfferModal(true)
        }}
        key='offer-details-cancel-button'
        fullWidth
        dataTestId='offer-details-cancel-button'
      >
        <Translate value='offerDetailsTranslations.cta.buttons.cancelAgreement' />
      </Button>,
    )
  }

  const agreementCta = hideCreateForm ? 'showCreate' : 'createForm'

  const currentContainer =
    offer && getCurrentContainer(containerList, offer.container)

  /**
   * @description: Method to get the correct string for title and description in the call to action box when having
   * the possibility do create a new Order. This is needed due to the case where the user cannot create a new Order,
   * or when he cans but its not his agreement (Makler). In this cases the text must be different both for title and
   * description when the agreementCtaType is `showCreate`
   * @param: agreementCtaType
   */
  const getAgreementCtaText = agreementCtaType => {
    switch (agreementCtaType) {
      case 'showCreate':
        if (
          !user.company ||
          offer.order_type !== OFFER_ORDER_TYPE.TYPE_ON_DEMAND ||
          (offer.company_object && offer.company_object.id === user.company)
        ) {
          return {
            title: I18n.t(
              'agreementDetailsTranslations.cta.showCreate.title.wasteDisposer',
            ),
            description: I18n.t(
              'agreementDetailsTranslations.cta.showCreate.text.wasteDisposer',
            ),
          }
        }
        return {
          title: I18n.t(
            'agreementDetailsTranslations.cta.showCreate.title.wasteProducer',
          ),
          description: I18n.t(
            'agreementDetailsTranslations.cta.showCreate.text.wasteProducer',
          ),
        }

      case 'createForm':
        return {
          title: I18n.t('agreementDetailsTranslations.cta.createForm.title'),
          description: (
            <Translate
              value='agreementDetailsTranslations.cta.createForm.text'
              reactionTime={I18n.t(
                `constant.date.interval.hours${
                  currentContainer!.reaction_time
                }`,
              )}
              dangerousHTML
            />
          ),
        }
      default:
        return {
          title: '',
          description: '',
        }
    }
  }

  const bigScreenCallToActionHeader = (
    <div
      className='inquiry-details-page__cta-info'
      key='inquiry-details-closed-header'
    >
      <CallToActionHeader
        title={getAgreementCtaText(agreementCta).title}
        key='agreement-details-create-offer'
        icon={<Truck className='inquiry-details-page__cta-icon' />}
      />
      <Paragraph align={PARAGRAPH_ALIGN.LEFT}>
        {getAgreementCtaText(agreementCta).description}
      </Paragraph>
    </div>
  )

  return (
    <Media query={{ maxWidth: BREAKPOINT.LARGE - 1 }}>
      {isSmallScreen => (
        <Formik
          initialValues={{
            delivery_date: getDefaultDate(
              currentContainer!.reaction_time,
            ).format('L'),
            time_of_day_delivery: OFFER_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY,
            number_of_containers: offer.number_of_containers,
            quantity_in_cubic_meters: decimalToGermanFormat(
              offer.quantity_in_cubic_meters,
            ),
            additional_information: '',
          }}
          onSubmit={values => {
            const valuesToSend = {
              ...values,
              quantity_in_cubic_meters: germanDecimalToInternationalFormat(
                values.quantity_in_cubic_meters,
              ),
            }

            dispatch(
              createOrder({
                default_delivery_date: false,
                offer: offer!.id,
                ...valuesToSend,
              }),
            )
          }}
          validationSchema={() =>
            yup.object().shape({
              delivery_date: yup.string(),
              time_of_day_delivery: yup.number().required(),
              number_of_containers: yup.number(),
              // needs to be string because the input decimal field works with strings, otherwise, all the comma rules
              // will not work
              quantity_in_cubic_meters: yup.string(),
            })
          }
          validate={values => {
            const errors: {
              delivery_date?: string
              time_of_day_delivery?: string
              number_of_containers?: string
              quantity_in_cubic_meters?: string
            } = {}

            if (
              !values.delivery_date ||
              !moment(values.delivery_date, 'L').isValid()
            ) {
              errors.delivery_date = ' '
            }

            if (showQuantityInCubicMeters) {
              if (
                typeof values.quantity_in_cubic_meters === 'string' &&
                values.quantity_in_cubic_meters === ''
              ) {
                errors.quantity_in_cubic_meters = ' '
              }
              if (
                !Number.isNaN(Number(values.quantity_in_cubic_meters)) &&
                Number(values.quantity_in_cubic_meters) <= 0
              ) {
                errors.quantity_in_cubic_meters = ' '
              }
            } else {
              if (
                typeof values.number_of_containers === 'string' &&
                values.number_of_containers === ''
              ) {
                errors.number_of_containers = ' '
              }
              if (
                !Number.isNaN(Number(values.number_of_containers)) &&
                (Number(values.number_of_containers) <= 0 ||
                  Number(values.number_of_containers) > 10)
              ) {
                errors.number_of_containers = ' '
              }
            }

            return errors
          }}
        >
          {({
            dirty = false,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldTouched,
            setFieldValue,
            submitCount,
            touched,
            values,
          }) => {
            const formProps = {
              dirty,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              isValid,
              setFieldValue,
              setFieldTouched,
              submitCount,
              touched,
              values,
            }

            const onClickSubmit = () => {
              handleSubmit()
              setHideCreateForm(true)
            }

            if (!hideCreateForm) {
              callToAction.hideMoreInfo = false
              callToAction.buttonsOrFields = [
                /* CREATE ORDER BUTTON */
                <Button
                  backgroundColor={
                    (isValid || submitCount < 1) && !isLoading.createOrder
                      ? BUTTON_BACKGROUND_COLOR.PRIMARY
                      : BUTTON_BACKGROUND_COLOR.WHITE
                  }
                  className={classNames(
                    'inquiry-details-page__create-order-button',
                    {
                      'inquiry-details-page__create-order-button--remove-margin-for-mobile':
                        isSmallScreen,
                    },
                  )}
                  dataTestId='order-create-button'
                  key='order-create-button'
                  isDisabled={
                    (submitCount > 0 && !isValid) ||
                    (isSubmitting && isLoading.createOrder)
                  }
                  noHover={
                    (submitCount > 0 && !isValid) ||
                    (isSubmitting && isLoading.createOrder)
                  }
                  onClick={() =>
                    (isValid || submitCount < 1) && onClickSubmit()
                  }
                  fullWidth
                >
                  <Translate value='agreementDetailsTranslations.cta.buttons.submit' />
                </Button>,
                <ErrorMessages
                  key='offer-create-errors'
                  errors={errors}
                  submitCount={submitCount}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  translationBase='agreementDetailsTranslations'
                />,
              ]

              if (isSmallScreen) {
                sections.set('newOrder', {
                  offset: -240,
                  title: (
                    <Translate value='agreementDetailsTranslations.section.newOrder.title' />
                  ),
                  component: (
                    <NewOrderSection
                      addScrollElement='new-order'
                      containerList={containerList}
                      formProps={formProps}
                      offer={offer}
                      key='newOrderSection'
                    />
                  ),
                })
              } else {
                callToAction.buttonsOrFields.unshift(
                  <>
                    <NewOrderFields
                      key='new-order-fields'
                      offer={offer}
                      showQuantityInCubicMeters={showQuantityInCubicMeters}
                      {...formProps}
                    />
                    {!!offer.customer_company_credit_limit
                      .no_of_unpaid_invoices && (
                      <div className='error-warning'>
                        <DismissableInfo
                          title={I18n.t('general.information')}
                          text={
                            <Translate
                              value='agreementDetailsTranslations.creditLimitInfo'
                              sumOfUnpaidInvoices={String(
                                offer.customer_company_credit_limit
                                  .sum_of_unpaid_invoices,
                              ).replace('.', ',')}
                              noOfUnpaidInvoices={
                                offer.customer_company_credit_limit
                                  .no_of_unpaid_invoices
                              }
                              companyName={offer.customer_company_object.name}
                              dangerousHTML
                            />
                          }
                          buttonText={I18n.t('general.button.submit')}
                        />
                      </div>
                    )}
                  </>,
                )
              }
            }

            if (!isSmallScreen)
              callToAction.header = bigScreenCallToActionHeader

            return (
              <>
                <DetailsPage
                  breadcrumb={breadcrumb}
                  callToAction={callToAction}
                  formProps={formProps}
                  offer={offer}
                  pageTitle={I18n.t('agreementDetailsTranslations.pageTitle', {
                    agreementId: offer!.id,
                  })}
                  pageType={PageType.AGREEMENT}
                  sections={sections}
                  user={user}
                />

                {/* Modals */}
                <ConvertInquiryModal
                  callback={() => setShowConvertInquiryModal(false)}
                  showConvertInquiryModal={showConvertInquiryModal}
                  offer={offer}
                />

                <CancelOfferModal
                  callback={() => setShowCancelOfferModal(false)}
                  offer={offer}
                  state={showCancelOfferModal}
                  user={user}
                  isAgreement
                />

                <PostponeIntervalModal
                  callback={() => setShowPostponeIntervalModal(false)}
                  showConvertInquiryModal={showPostponeIntervalModal}
                  offer={offer}
                />

                <ChangeDisposerModal
                  callback={() => {
                    dispatch(resetApiFetchLoading('GET_PRICE_COMPARISON_TABLE'))
                    dispatch(resetPriceComparisonTable())
                    setShowChangeDisposerModal(false)
                  }}
                  showChangeDisposerModal={showChangeDisposerModal}
                  offer={offer}
                  containerList={containerList}
                />

                <ChangeAgreementPeriodModal
                  callback={() => {
                    setShowChangeAgreementIntervalModal(false)
                  }}
                  offer={offer}
                  showChangeAgreementIntervalModal={
                    showChangeAgreementIntervalModal
                  }
                />
              </>
            )
          }}
        </Formik>
      )}
    </Media>
  )
}

export const AgreementDetailsPage = withApiErrorHandling(
  AgreementDetailsPageComponent,
  ['GET_OFFER'],
  ['GET_OFFER'],
)
